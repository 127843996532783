import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../order.service';
import { Store } from '../models/store';
import { OrdersWithPayment, Order, Modifier, BotOrder } from '../models/order';
import * as moment from 'moment';
import { Constants } from '../constants';
import { OrderHelpers } from '../order-summary/utils/helpers';
import { ArrivedDialogComponent } from '../arrived-dialog/arrived-dialog.component';
import { OrderSocketService } from '../_services/order-socket.service';
import { StoreService } from '../_services/store.service';
import { IMqttMessage, IPublishOptions, MqttService } from "ngx-mqtt";
import { IClientSubscribeOptions } from "mqtt/types/lib/client-options";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit, OnDestroy {

  tt;
  color = 'red';
  orderHash: string;
  status: string;
  orderWithPayment: OrdersWithPayment;
  store: Store;
  initial: string;
  displayedColumns: string[] = ['quantity', 'item', 'itemTotal'];
  pizzaOptions: Map<string, string> = Constants.pizzaOptions
  pizzaOptions$:Map<string, string> = Constants.pizzaOptionsFlag;
  estimatedTime: Date;

  curbsideConfirm = false;
  ends;
  submittedAt;
  uiSetting: any;
  isLoading = true;
  reorder = false;
  showReorder = false;

  errorMessages = {
    'Expired card': 'Expired Card: Sorry we could not process your order. The current card selected is Expired. Please change the payment type and resubmit your order.',
    'Decline': 'Decline: Sorry we could not process your order. The current card selected is being declined. Please change the payment type and resubmit your order.',
    'Invalid card': 'Invalid Card: Sorry we could not process your order. The current card selected is invalid. Please change the payment type and resubmit your order.',
    'Not sufficient funds': 'Insufficient Funds: Sorry we could not process your order. The current card selected has insufficient funds to make payments. Please change the payment type and resubmit your order.',
    'Invalid account number': 'Invalid Account Number: Sorry we could not process your order. The current card selected has an invalid card number. Please change the payment type and resubmit your order.',
    'Restricted card': 'Restricted Card: Sorry we could not process your order. The current card selected has a restricted card number. Please change the payment type and resubmit your order.',
    'Transaction not permitted to cardholder': 'Transaction not permitted the cardholder: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Service not allowed': 'Service Not Allowed: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Invalid transaction': 'Invalid Transaction: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Wrong expiration': 'Wrong Expiration Date: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Timed out': 'Timed out: Sorry we could not process your order. Please click back, wait a couple of minutes, and resubmit your order.',
    'Do not honor': 'Do not honor: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Non-numeric expiry': 'Non-numeric expiry: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Declined': 'Decline: Sorry we could not process your order. The current card selected is being declined. Please change the payment type and resubmit your order.',
    'Invalid issuer': 'Invalid Issuer: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Invalid amount': 'Invalid Amount: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Hierarchy validation': 'Hierarchy Validation: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'ADD AUTH REQUIRE': 'ADD AUTH REQUIRE: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Invalid merchant': 'Invalid Merchant: Sorry we could not process your order. Please update or change the payment type and resubmit your order.',
    'Invalid Timezone': 'Please check our posted hours of operation and try again later. You are receiving this message because you may have your timezone settings on your device set to the incorrect timezone.'
  }
  error;

  prgrsBar1: number;
  step1: string;
  c_color1: string;
  bar_color1: string;
  current_status1: boolean;
  prgrsBar2: number;
  step2: string;
  c_color2: string;
  bar_color2: string;
  current_status2: boolean;
  prgrsBar3: number;
  step3: string;
  c_color3: string;
  bar_color3: string;
  current_status3: boolean;
  mqtSub;
  spotData: any;
  statusinterval: any;
  reOrderItem: boolean;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    public dialog: MatDialog,
    private router: Router,
    private orderSocketService: OrderSocketService,
    private storeService: StoreService,
    private mqttService: MqttService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.orderHash = this.route.snapshot.paramMap.get('o_hash');
    this.status = this.route.snapshot.paramMap.get('status');
    // this.subMqtt();

    this.orderService.getOrder(this.orderHash).subscribe(
      data => {
        this.orderWithPayment = Object.assign(new OrdersWithPayment(), data, {
          bot_order: Object.assign(new BotOrder(), data.bot_order, {
            occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
            created_at: moment(data.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
            updated_at: moment(data.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
            submitted_at: moment(data.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
          }),
          store: Object.assign(new Store(), data.store),
          items: data.items.map(o => Object.assign(new Order(), o, {
            modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
          })),
          payment: OrderHelpers.parsePayment(data.payment)
        });
        this.initial = this.orderWithPayment.store.store_name.split(" ").map(p => p[0]).join("")
        let failure = Object.keys(this.errorMessages).filter(m => this.orderWithPayment.bot_order.failure_reason.includes(m));
        if (failure.length <= 0) {
          this.error = `Gateway response: ${this.orderWithPayment.bot_order.failure_reason}`
        } else {
          this.error = this.errorMessages[failure[0]];
        }
        console.log(this.error, failure);
        this.uiSetting = this.orderWithPayment.store.ui_settings;
        this.isLoading = false;
        this.orderWithPayment.items.forEach(item => this.reorder = this.reorder && item.menu_item.is_available)

        console.log('Order in success URL', this.orderWithPayment);
        this.storeService.changeStore(this.orderWithPayment.store);
        this.orderTrackingStatus(this.orderWithPayment);
        this.route.queryParams.subscribe(params => {
          if ('reorder' in params) {
            this.showReorder = true
            if (params.reorder == 'true'){
              this.reorder = this.orderWithPayment.store.isEnabled('reorder')
              if (this.orderWithPayment.store.isEnabled('reorder')) {
                this.orderService.checkReorderMenuItem(this.orderWithPayment.bot_order.order_hash).subscribe((data:any) => {
                  this.reOrderItem = data.data;
                  this.reorder = data.data;
                  if (!this.reOrderItem) {
                    this.snackBar.open('Selected menu item or modifier is not available', "OK", {
                      verticalPosition: 'top'
                    })
                  }
                });
              }
            }
            else{
              this.reorder = false;
            }
          }
        })

      },
    err => { console.log(err) });

    if (this.status == "success") {
      this.statusinterval = setInterval(() => {
        this.getOrderStatus();
      }, 30000);
    }
  }

  goBack() {
    window.history.back()
  }

  informStore(): void {

    const dialogRef = this.dialog.open(ArrivedDialogComponent, {
      width: '350px',
      disableClose: true,
      data: {
        confirmText: 'I\'m here',
        message: 'Please make sure you are parked in a designated, legal, safe place.',
        parking: 1,
        spot_field: this.orderWithPayment.store.is_spot_field_mandatory
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      this.isLoading = true;
      if (data == true) {
        if (this.orderWithPayment.store.is_spot_field_mandatory) {
          this.spotData = dialogRef.componentInstance.form.get('name').value;
        } else {
          this.spotData = '';
        }
        this.orderService.curbsideConfirm(this.orderHash, this.spotData).subscribe(data => {
          this.orderWithPayment.bot_order.curbside_drop_off = true;
          this.isLoading = false;
        },
          (error) => {
            if (error.status == 400) {
              this.isLoading = false;
              this.orderWithPayment.bot_order.curbside_drop_off = true;
            }
          });
      } else {
        this.isLoading = false;
      }
    });
  }

  reOrder() {
    this.orderService.reOrder(this.orderHash).subscribe(response => {
      this.router.navigate(['order', response.order_hash])
    });
  }

  orderTrackingStatus(order) {
    if (order) {
      if (order.bot_order.is_cancelled) {
        this.prgrsBar1 = 100;
        this.step1 = 'Declined';
        this.c_color1 = 'red';
        this.bar_color1 = 'red';
        this.current_status1 = false;

        this.prgrsBar2 = 100;
        this.step2 = 'Preparing';
        this.c_color2 = 'grey';
        this.bar_color2 = 'grey';
        this.current_status2 = false;

        this.prgrsBar3 = 100;
        this.step3 = 'Ready!';
        this.c_color3 = 'grey';
        this.bar_color3 = 'grey';
        this.current_status3 = false;

        clearInterval(this.statusinterval);
      } else {
        if (!order.bot_order.view_notified && !order.bot_order.ready_notified) {
          this.prgrsBar1 = 100;
          this.step1 = 'Submitting';
          this.c_color1 = 'orange';
          this.bar_color1 = 'grey';
          this.current_status1 = true;

          this.prgrsBar2 = 100;
          this.step2 = 'Preparing';
          this.c_color2 = 'grey';
          this.bar_color2 = 'grey';
          this.current_status2 = false;

          this.prgrsBar3 = 0;
          this.step3 = 'Ready!';
          this.c_color3 = 'grey';
          this.bar_color3 = 'grey';
          this.current_status3 = false;
        }
        else if (order.bot_order.view_notified && !order.bot_order.ready_notified) {
          this.prgrsBar1 = 100;
          this.step1 = 'Received';
          this.c_color1 = 'green';
          this.bar_color1 = 'green';
          this.current_status1 = false;

          this.prgrsBar2 = 100;
          this.step2 = 'Preparing';
          this.c_color2 = 'orange';
          this.bar_color2 = 'grey';
          this.current_status2 = true;

          this.prgrsBar3 = 0;
          this.step3 = 'Ready!';
          this.c_color3 = 'grey';
          this.bar_color3 = 'grey';
          this.current_status3 = false;
        }
        else if (order.bot_order.view_notified && order.bot_order.ready_notified) {
          this.prgrsBar1 = 100;
          this.step1 = 'Received';
          this.c_color1 = 'green';
          this.bar_color1 = 'green';
          this.current_status1 = false;

          this.prgrsBar2 = 100;
          this.step2 = 'Preparing';
          this.c_color2 = 'green';
          this.bar_color2 = 'green';
          this.current_status2 = false;

          this.prgrsBar3 = 100;
          this.step3 = 'Ready!';
          this.c_color3 = 'green';
          this.bar_color3 = 'green';
          this.current_status3 = false;

          clearInterval(this.statusinterval);
        }
      }
      this.tt = [
        {

          "percent": this.prgrsBar1,
          "name": this.step1,
          "color": this.c_color1,
          "bar_color": this.bar_color1,
          "current_status": this.current_status1
        },
        {

          "percent": this.prgrsBar2,
          "name": this.step2,
          "color": this.c_color2,
          "bar_color": this.bar_color2,
          "current_status": this.current_status2

        },
        {

          "percent": this.prgrsBar3,
          "name": this.step3,
          "color": this.c_color3,
          "bar_color": this.bar_color3,
          "current_status": this.current_status3
        }
      ]
    }
  }

  subMqtt() {
    let currentStoreId = localStorage.getItem("currentStore")
    this.mqtSub = this.mqttService.observe(`/order_status/${currentStoreId}/sub`,
      { qos: 1 } as IClientSubscribeOptions).subscribe((message: IMqttMessage) => {
        const response = JSON.parse(message.payload.toString());
        if (response) {
          if (this.orderWithPayment.bot_order.id == response.bot_order.id) {
            this.orderWithPayment = Object.assign(new OrdersWithPayment(), response, {
              bot_order: Object.assign(new BotOrder(), response.bot_order, {
                occasion_schedule: moment(response.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
                created_at: moment(response.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
                updated_at: moment(response.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
                submitted_at: moment(response.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
              }),
              store: Object.assign(new Store(), response.store),
              items: response.items.map(o => Object.assign(new Order(), o, {
                modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
              })),
              payment: OrderHelpers.parsePayment(response.payment)
            });
            this.orderTrackingStatus(response);
          }
        }
      });
  }

  getOrderStatus() {
    this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject)).subscribe((response: any) => {
      if (this.orderWithPayment.bot_order.id == response.bot_order.id) {
        this.orderWithPayment = Object.assign(new OrdersWithPayment(), response, {
          bot_order: Object.assign(new BotOrder(), response.bot_order, {
            occasion_schedule: moment(response.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate(),
            created_at: moment(response.bot_order.created_at, 'hh:mm A MMM DD, YYYY').toDate(),
            updated_at: moment(response.bot_order.updated_at, 'hh:mm A MMM DD, YYYY').toDate(),
            submitted_at: moment(response.bot_order.submitted_at, 'hh:mm A MMM DD, YYYY').toDate()
          }),
          store: Object.assign(new Store(), response.store),
          items: response.items.map(o => Object.assign(new Order(), o, {
            modifiers: o.modifiers.map(x => Object.assign(new Modifier(), x))
          })),
          payment: OrderHelpers.parsePayment(response.payment)
        });
        this.orderTrackingStatus(response);
      }
    });
  }

  durationAsString(start, end) {
    const duration = moment.duration(moment(end).diff(moment(start)));

    //Get Days
    const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
    const daysFormatted = days ? `${days} days, ` : ''; // if no full days then do not display it at all

    //Get Hours
    const hours = duration.hours();
    const hoursFormatted = `${hours} hrs, `;

    //Get Minutes
    const minutes = duration.minutes();
    const minutesFormatted = `${minutes} minutes`;

    return [daysFormatted, hoursFormatted, minutesFormatted].join('');
  }

  ngOnDestroy() {
    if (this.mqtSub) {
      this.mqtSub.unsubscribe();
    }
    if (this.statusinterval) {
      clearInterval(this.statusinterval);
    }
    this.destroySubject.next();
  }

}

