import {Component, Inject, Input, OnInit} from '@angular/core';
import {OrdersWithPayment} from "../../models/order";
import {extendMoment} from "moment-range";

import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
const { range } = extendMoment(moment);

@Component({
  selector: 'app-schedule-order-dialog',
  templateUrl: './schedule-order-dialog.component.html',
  styleUrls: ['./schedule-order-dialog.component.sass']
})
export class ScheduleOrderDialogComponent implements OnInit {

  @Input() order: OrdersWithPayment;

  occasionSchedule;
  scheduledDate;
  scheduledTime;

  dateRange;
  timeRange;

  constructor(public dialogRef: MatDialogRef<ScheduleOrderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.data.order){
      this.order = this.data.order;
      this.buildTimeRange();
    }
  }

  buildTimeRange(){
    var start = null
    var leadTime = this.order.store.getLeadTime(this.order.bot_order.occasion);

    start = moment().add(leadTime, 'minutes')

    const remainder = 15 - (start.minute() % 15);
    start = moment(start).add(remainder, "minutes")

    this.occasionSchedule = start
    this.scheduledDate = this.occasionSchedule.format("ddd, MMM D");
    this.scheduledTime = this.occasionSchedule.format("hh:mm A");

    let startDate = new Date()
    let endDate = moment(startDate).add(7, 'days');
    let range1 = range([startDate, endDate]);
    this.dateRange = Array.from(range1.by('day'));
    this.onDateChange(moment(startDate).format('ddd, MMM DD'), leadTime);
  }

  onDateChange(date, leadTime){
    console.log(date);
    const start = moment(date, 'ddd, MMM DD').toDate()
    const times = 24 * 4; // 24 hours * 15 mins in an hour
    this.timeRange = new Array(times)

    for (let i = 0; i < times; i++) {
      const toPrint = moment(start)
        .add(15 * i, 'minutes')
      if(this.order.store.is_open_on(toPrint.toDate(), leadTime)){
        console.log("open", toPrint.toDate());
        this.timeRange[i] = toPrint
      }
    }
    this.timeRange = this.timeRange.filter(Boolean)
  }

}
