export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyBKHYGsT9uTznTz5FOFxqVxjXeXyyUxkH0",
    authDomain: "labrador-frontend.firebaseapp.com",
    databaseURL: "https://labrador-frontend.firebaseio.com",
    projectId: "labrador-frontend",
    storageBucket: "labrador-frontend.appspot.com",
    messagingSenderId: "917402468037"
  },
  orderApiBackend: "https://beta.labrador.ai",
  websocketHost:'wss://backend.order.labrador.ai',
  accessToken: "e53e7a20711c270f8e890cfda68ef84319d94378",
  iTokenUrl: "https://fts.cardconnect.com",
  hostName: "https://order.beta.labrador.ai",
  referralPinvrf: "https://referbeta.pinvrf.com"
};
