import {ChangeDetectorRef, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {get} from 'scriptjs';
import {OrdersWithPayment} from '../../models/order';
import {PaymentOption} from '../../_services/payment-option.service';
import {Store} from '../../models/store';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TDSCardCheckService} from '../../_services/tds.service';

declare var Gateway;

@Component({
  selector: 'app-threeds-check',
  templateUrl: './threeds-check.component.html',
  styleUrls: ['./threeds-check.component.sass']
})
export class ThreedsCheckComponent {

  @ViewChild('mountPoint', {read: ElementRef}) mountPoint: ElementRef;
  threeDS: any;
  status: string;

  nameOnCard: string;
  customerVaultId: string;
  total: number;

  hasError = false;
  errorMessage: string;
  hasChallenge = false;


  private readonly GATEWAY_URL = 'https://labrador.transactiongateway.com/js/v1/Gateway.js';
  private readonly GATEWAY_ID: string;
  private readonly CURRENCY = 'USD';

  constructor(public dialogRef: MatDialogRef<ThreedsCheckComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private ref: ChangeDetectorRef) {
    console.log(data)
    this.nameOnCard = data.nameOnCard;
    this.customerVaultId = data.customerVaultId;
    this.total = data.total;
    this.GATEWAY_ID = data.gatewayId;
    this.status = ""
    this.setup3ds();
  }

  setup3ds() {
    get(this.GATEWAY_URL, () => {
      this.setupGateway();
    });
  }

  private setupGateway() {
    const gateway = Gateway.create(this.GATEWAY_ID);
    this.threeDS = gateway.get3DSecure();
    const threeDSConfig = this.getThreeDSConfig();
    const threeDSInterface = this.threeDS.createUI(threeDSConfig);
    this.setUpThreeDSInterfaceEvents(threeDSInterface);
  }

  private getThreeDSConfig() {
    const name = this.nameOnCard;
    let firstName = null;
    let lastName = null;
    if(name){
      const index = name.indexOf(" ");
      firstName = name.slice(0, index); // Gets the first part
      lastName = name.slice(index + 1);
    }else {
      firstName = "VISA"
      lastName = "CARDHOLDER"
    }

    return {
      amount: this.total,
      currency: this.CURRENCY,
      customerVaultId: this.customerVaultId,
      firstName: firstName,
      lastName: lastName
    }
  }

  private setUpThreeDSInterfaceEvents(threeDSInterface) {
    threeDSInterface.start('#mount-point');
    threeDSInterface.on('complete', (data) => this.dialogRef.close(data));

    threeDSInterface.on('challenge', function(e) {
      console.log('Challenged');
      this.hasChallenge = true;
      this.ref.detectChanges();
    }.bind(this));

    threeDSInterface.on('failure', function (e) {
      console.error('failure', e);
      this.hasError = true;
      this.errorMessage = e.message;
      this.hasChallenge=false;
      this.ref.detectChanges();
    }.bind(this));

    threeDSInterface.on('error', (error) => {
      console.log(error)
      this.hasError = true;
      this.errorMessage = error.message;
      this.hasChallenge= false;
      this.ref.detectChanges();
    });
  }

  close(){
    this.dialogRef.close(false);
  }
}
