import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrdersWithPayment } from 'src/app/models/order';
import { OrderService } from 'src/app/order.service';
import {MatDialog} from '@angular/material/dialog';
import {CustomTipDialogComponent} from './custom-tip-dialog/custom-tip-dialog.component';

@Component({
  selector: 'app-order-summary-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnInit {

  @Input() orderWithPayment:OrdersWithPayment;
  @Input() orderHash:string;

  tipPercent:number = 0

  @Output() refresh = new EventEmitter<any>();

  tips = [
    {"name": "18%", "value": 18},
    {"name": "20%", "value": 20},
    {"name": "25%", "value": 25},
    {"name": "Cash", "value": 0},
  ]

  customTipHidden = true;
  constructor(
    private orderService:OrderService,
    private dialog: MatDialog
  ) { }

  toggleCustomTip(){
    this.customTipHidden = !this.customTipHidden
  }

  calculateTip(change:any){
    console.log(change);

    this.customTipHidden = true;
    this.orderWithPayment.updateTip(change.value)
    this.orderService.updateTip(this.orderHash, change.value).subscribe(data => {
      this.refresh.emit(true)
    })
  }

  calculateCustomTip(tip:number){
    this.orderService.updateCustomTip(this.orderHash, tip).subscribe(data => {
      this.orderWithPayment.updateTipCustom(+tip);
      this.toggleCustomTip();
      this.refresh.emit(true)
    })
  }

  ngOnInit() {
    this.tipPercent = this.orderWithPayment.getTipPercent()
    // const storeTipOptions = this.orderWithPayment.getTipPercentOptions()
    // if(storeTipOptions.length > 0){
    //   this.tips = storeTipOptions.map(t => {
    //     return {"name": `${t.percent}%`, "value": t.percent}
    //   })
    //   this.tips.push({"name": "Cash", "value": 0})
    // }
  }

  openCustomTip(){
    let dialogRef = this.dialog.open(CustomTipDialogComponent,
      {width: '80vw'});
    dialogRef.afterClosed().subscribe(result => {
      this.calculateCustomTip(result);
    })
  }

}
